import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

// eslint-disable-next-line import/no-named-as-default-member
i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	// want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: 'en',
		debug: false,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		react: {
			useSuspense: true,
		},
		resources: {
			en: {
				translation: {
					show: 'show',
					logoutMessage: 'are you sure to logout ?',
					logout: 'logout',
					cancel: 'cancel',
					LightMode: 'light mode',
					DarkMode: 'dark mode',
					search: 'search',
					distribution: 'employees distribution',
					totals: {
						clinics: 'total clinics',
						users: 'total users',
						expires: 'total expired',
					},
					admin: {
						lastWeekApp: 'last week appointments',
						lastWeekInv: 'last week invoices',
						storeItems: 'store items',
						appFromWeek: 'appointments from a week',
						paidAppFromWeek: 'paid appointments from a week',
						totalPaid: 'not paid yet',
						main: 'main',
						insurance_company: 'insurance company',
						appointment: 'appointment',
						invoices: 'invoices',
						doctorInvoices: 'doctor invoices',
						store: 'store history',
						services: 'services',
						employees: 'epmloyees',
						settings: 'settings',
						clinicInsuranceCompanies: 'clinic insurance companies',
						companies: 'companies',
						addCompany: 'add insurance company',
						clinicApp: 'clinic appointments',
						show: 'show',
						clinicInvoices: 'clinic invoices',
						report: 'print report',
						all: 'all',
						cash: 'cash',
						kent: 'kent',
						insuranceCompany: 'insurance company',
						clinicDoctorInvoices: 'clinic doctor invoices',
						clinicStore: 'clinic store',
						created: 'created',
						updated: 'updated',
						deleted: 'deleted',
						clinicServices: 'clinic services',
						medicalServices: 'medical services',
						addServices: 'add services',
						clinicEmployees: 'clinic epmloyees',
						clinicSetting: 'clinic settings',
					},
					dashboard: {
						allClinics: 'all clinics',
						fst10Clinics: 'first 10 clinics',
						lst10Clinics: 'last 10 clinics',
						addNewClinic: 'new clinic',
						addClinicBranch: 'clinic branch',
						addSpecialization: 'add specialization',
						addSubscription: 'add subscription',
						addUser: 'add clinic user',
						addUserSpecialization: 'add specialization',
						dashboard: 'dashboard',
						clinics: 'clinics',
						specializations: 'specializations',
						subscriptions: 'subscriptions',
						billings: 'billings',
						expires: 'expires clinics',
						employees: 'users',
						employeesSpecializations: 'users specializations',
					},
					patient: {
						main: 'main',
						hi: 'hi',
						settings: 'settings',
						totalApp: 'total appointments',
						app: 'appointments',
						totalPaidApp: 'paid appointments',
						addApp: 'add new appointment',
						medicalRecord: 'medical record',
					},
					storeKeeper: {
						main: 'main',
						addItem: 'add item',
					},
					reception: {
						appToday: 'appointments today',
						companies: 'companies',
						paidApp: 'paid appointments since today',
						patientToInsurance: 'patients to insurance companies',
						AppAssignedDoctor: 'appointment indicated by a doctor',
						addPatients: 'add patients',
						PatientPhone: 'phone number',
						assign: 'join to company',
						addNewApp: 'add new appointment',
						main: 'main',
						patients: 'patients',
						appointments: 'appointment',
						calender: 'calendar',
					},
					doctor: {
						main: 'main',
						appointments: 'appointments',
						examinations: 'examinations',
						calendar: 'calendar',
						store: 'store',
						totalApp: 'total appointments',
						totalExam: 'total examination',
						examFromWeek: 'examinations from aweek',
						storeItems: 'store items',
						pullFromStore: 'pull out from store',
						storeHistory: 'store history',
					},
					tabels: {
						logo: 'logo',
						name: 'name',
						phone: 'phone',
						code: 'code',
						desc: 'description',
						edit: 'edit',
						delete: 'delete',
						patientName: 'patient name',
						patientPhone: 'patient code',
						national_id: 'national id',
						date: 'date',
						startTime: 'start time',
						notes: 'notes',
						amount: 'amount',
						payMethod: 'payment method',
						email: 'email',
						transactions: 'transactions',
						event: 'event',
						title: 'title',
						quantity: 'quantity',
						price: 'price',
						roles: 'roles',
						accommodation: 'accommodation',
						descriptions: 'descriptions',
						action: 'action',
						reason: 'reason',
						history: 'history',
						diagnose: 'diagnose',
						time: 'time',
						add: 'add',
						rosheta: 'rosheta',
						services: 'service',
						oldQuantity: 'old quantity',
						newQuantity: 'new quantity',
						pullOut: 'pull out',
						payment: 'patment',
						cost: 'cost',
						downloadRosheta: 'download rosheta',
						height: 'height',
						weight: 'weight',
						visible: 'visible',
						insuranceCompanyCard: 'insurance company card',
						doctorAssign: 'doctor assign',
						appointmentStatus: 'appointment status',
						remainDays: 'remain days',
						specializations: 'specializations',
						activation: 'activation',
						staff: 'staff',
						createdDate: 'created date',
						periodicityType: 'periodicity type',
						periodicity: 'periodicity',
						gracePeriod: 'grace period',
						usersNumber: 'users number',
						clinicName: 'clinic name',
						plan:'plan name',
						frequency:'frequency',
						invoice:'invoice',
						city:'city',
						address:'address',
						renewPlans:'renew plan',
						
					},
				},
			},
			ar: {
				translation: {
					show: 'عرض',
					logoutMessage: 'هل متأكد من تسجيل الخروج ؟',
					cancel: 'إلغاء',
					LightMode: 'الوضع المضئ',
					DarkMode: 'الوضع المظلم',
					logout: 'تسجيل الخروج',
					search: 'بحث',
					distribution: 'توزيع المستخدمين',
					totals: {
						clinics: 'إجمالي العيادات',
						users: 'إجمالي المستخدمين',
						expires: 'إجمالي المنتهي الصلاحية',
					},
					admin: {
						lastWeekApp: 'حجوزات الأسبوع الماضي',
						lastWeekInv: 'فواتير الأسبوع الماضي',
						storeItems: 'عناصر المخزن',
						appFromWeek: 'حجوزات منذ أسبوع',
						paidAppFromWeek: 'حجوزات مدفوعة منذ أسبوع',
						totalPaid: 'ما لم يتم دفعها',
						main: 'الرئيسية',
						insurance_company: 'شركات التأمين',
						appointment: 'الحجوزات',
						invoices: 'الفواتير',
						doctorInvoices: 'فواتير الأطباء',
						store: 'المخزن',
						services: 'الخدمات',
						employees: 'الموظفون',
						settings: 'الإعدادات',
						clinicInsuranceCompanies: 'شركات تأمين العيادة',
						companies: 'الشركات',
						addCompany: 'إضافة شركة تأمين',
						clinicApp: 'حجوزات العيادة',
						show: 'عرض',
						clinicInvoices: 'فواتير العيادة',
						report: 'طباعة تقرير',
						all: 'الجميع',
						cash: 'نقدي',
						kent: 'كينت',
						insuranceCompany: 'شركة تأمين',
						clinicDoctorInvoices: 'فواتير أطباء العيادة',
						clinicStore: 'مخزن العيادة',
						created: 'تم إنشاؤه',
						updated: 'معدل',
						deleted: 'محذوف',
						clinicServices: 'خدمات العيادة',
						medicalServices: 'الخدمات الطبية',
						addServices: 'اضافة خدمة',
						clinicEmployees: 'موظفون العيادة',
						clinicSetting: 'إعدادات العيادة',
					},
					dashboard: {
						allClinics: 'كل العيادات',
						fst10Clinics: 'أول ١٠ عيادات',
						lst10Clinics: 'أخر ١٠ عيادات',
						addNewClinic: 'عيادة جديدة',
						addClinicBranch: 'فرع جديد',
						addSpecialization: 'إضافة تخصص',
						addSubscription: 'إضافة إشتراك',
						addUser: 'إضافة مستخدم',
						addUserSpecialization: 'إضافة تخصص مستخدم',
						dashboard: 'لوحة التحكم',
						clinics: 'العيادات',
						specializations: 'التخصصات',
						subscriptions: 'الإشتراكات',
						billings: 'الفواتير',
						expires: 'عيادات منتهية الصلاحية',
						employees: 'الموظفون',
						employeesSpecializations: 'تخصصات الموظفين',
					},
					patient: {
						main: 'الرئيسية',
						hi: 'مرحباً',
						settings: 'الإعدادات',
						totalApp: 'إجمالي الحجوزات',
						app: 'الحجوزات',
						totalPaidApp: 'إجمالي الحجوزات المدفوعة',
						addApp: 'إضافة حجز جديد',
						medicalRecord: 'التقرير الطبي',
					},
					storeKeeper: {
						main: 'الرئيسية',
						addItem: 'إضافة عنصر',
					},
					reception: {
						appToday: 'حجوزات اليوم',
						companies: 'شركات التأمين',
						paidApp: 'الحجوزات المدفوعة حتي الان',
						patientToInsurance: 'المرضي المشتركين في شركات التأمين',
						AppAssignedDoctor: 'الحجوزات المشار اليه طبيب',
						addPatients: 'إضافة مريض',
						PatientPhone: 'رقم الموبايل',
						assign: 'ربط بشركة',
						addNewApp: 'إضافة حجز جديد',
						main: 'الرئيسية',
						patients: 'المرضي',
						appointments: 'الحجوزات',
						calender: 'الأجندة',
					},
					doctor: {
						main: 'الرئيسية',
						appointments: 'الحجوزات',
						examinations: 'الفحوصات',
						calendar: 'الأجندة',
						store: 'المخزن',
						totalApp: 'إجمالي الحجوزات',
						totalExam: 'إجمالي الفحوصات',
						examFromWeek: 'الفحوصات منذ أسبوع',
						storeItems: 'عناصر المخزن',
						pullFromStore: 'سحب من المخزن',
						storeHistory: 'سجل المخزن',
					},
					tabels: {
						logo: 'الشعار',
						name: 'الإسم',
						phone: 'التليفون',
						code: 'الكود',
						edit: 'تعديل',
						delete: 'حذف',
						patientName: 'إسم المريض',
						patientPhone: 'كود المريض',
						national_id: 'الرقم القومي',
						date: 'التاريخ',
						startTime: 'وقت البدء',
						notes: 'ملاحظات',
						amount: 'الكمية',
						payMethod: 'خاصية الدفع',
						email: 'الإيميل',
						transactions: 'التحويلات',
						event: 'الحدث',
						title: 'العنوان',
						quantity: 'الكمية',
						price: 'السعر',
						roles: 'المهام',
						accommodation: 'الإقامة',
						descriptions: 'الوصف',
						action: 'الحدث',
						reason: 'السبب',
						history: 'سجل',
						diagnose: 'تشخيص',
						time: 'التوقيت',
						add: 'إضافة',
						rosheta: 'روشتة',
						services: 'خدمات',
						oldQuantity: 'الكمية القديمة',
						newQuantity: 'الكمية الجديدة',
						pullOut: 'سحب',
						payment: 'الدفع',
						cost: 'التكلفة',
						downloadRosheta: 'تحميل الروشتة',
						height: 'الطول',
						weight: 'الوزن',
						visible: 'مرئي ؟',
						insuranceCompanyCard: 'بطاقة شركة التأمين',
						doctorAssign: 'ربط بدكتور',
						appointmentStatus: 'حالة الحجز',
						remainDays: 'الأيام المتبقية',
						specializations: 'التخصص',
						activation: 'التفعيل',
						staff: 'الموظفون',
						createdDate: 'تاريخ الإنشاء',
						periodicityType: 'نوع الدورية',
						periodicity: 'دورية',
						gracePeriod: 'فترة السماح',
						usersNumber: 'عدد المستخدمين',
						clinicName: 'إسم العيادة',
						plan:'إسم الخطة',
						frequency:'معدل التكرار',
						invoice:'الفاتورة',
						city:'المدينة',
						address:'العنوان',
						renewPlans:'تجديد الخطة'
					},
				},
			},
		},
	});

export default i18n;
