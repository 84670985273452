import React, { useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import axios from 'axios';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../components/bootstrap/Modal';
import { CardLabel } from '../../components/bootstrap/Card';
import Spinner from '../../components/bootstrap/Spinner';

interface IUsername {
	username: any;
}
const User = (props: IUsername) => {

	const [logoutStatus, setLogoutStatus] = useState(false);
	const [logoutSpinner, setLogoutSpinner] = useState(false);
	const logout = () => {
		setLogoutSpinner(true)
		axios.get(`https://ebaa.digithup.net/api/auth/logout`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		}).then(() => {
			setTimeout(()=>{
				localStorage.clear();
				window.location.reload();
			},500)
		}).catch(()=>{
			localStorage.clear();
			window.location.reload();
		})
	};

	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(true);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
				<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'></div>
				<div className='user-info navigation-text'>
					<div className='user-name d-flex align-items-center'>
						{`${props.username.split('@')[0]}`}
						<Icon icon='Verified' className='me-2 ms-2' color='info' />
					</div>
				</div>
			</div>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setLogoutStatus(true);
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text m-2'>
										{t('logout') }
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		
			<DropdownMenu>
				<DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
			</DropdownMenu>
			<Modal
					isOpen={logoutStatus}
					setIsOpen={setLogoutStatus}
					size='md'
					titleId={'0'.toString()}>
					<ModalHeader setIsOpen={setLogoutStatus} className='p-5'>
						<CardLabel icon='Help'>
							<h5 className='m-2'>{t('logoutMessage')}</h5>
						</CardLabel>
					</ModalHeader>
					<ModalFooter className='px-4 pb-4'>
						<Button color='info' isDisable = {logoutSpinner} onClick={logout}>
						{logoutSpinner ? (
										<Spinner className='me-3 ms-3' size={15} isSmall />
									) : 
										<>{t('logout') }</>
									}
						</Button>
						<Button
							color='danger'
							onClick={() => {
								setLogoutStatus(false);
							}}>
							{t('cancel') }
						</Button>
					</ModalFooter>
				</Modal>
		</>
	);
};

export default User;
