import React from 'react';
import { RouteProps } from 'react-router-dom';
import {dashboardPagesMenu} from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';

const headers: RouteProps[] = [

	{ path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
];

export default headers;
