import React, { ReactNode, useContext, useState ,useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { dashboardPagesMenu, AdminPagesMenu, PatientPagesMenu, DoctorPagesMenu, ReceptionPagesMenu, storeKeeperPagesMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Hand from '../../../assets/img/hand.png';
import HandWebp from '../../../assets/img/hand.webp';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const username = localStorage.getItem('facit_authUsername');
	const role = localStorage.getItem('role');

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);	
	const { t } = useTranslation(['translation', 'menu']);

	const { darkModeStatus } = useDarkMode();

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				{username && !doc && (
					<>
						{role === 'superAdmin' ? (
							<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
						) : role === 'Admin'  ? (
							<Navigation menu={AdminPagesMenu} id='aside-dashboard' />
						) : role === 'Patient'  ?(
							<Navigation menu={PatientPagesMenu} id='aside-dashboard' />
						): role === 'Doctor'  ?(
							<Navigation menu={DoctorPagesMenu} id='aside-dashboard' />
						):role === 'Receptionist'  ?(
							<Navigation menu={ReceptionPagesMenu} id='aside-dashboard' />
						):role === 'Storekeeper'  ?(
							<Navigation menu={storeKeeperPagesMenu} id='aside-dashboard' />
						):''}
					</>
				)}

				{asideStatus && doc && (
					<Card className='m-1'>
						<CardBody className='pt-0'>
							<img srcSet={HandWebp} src={Hand} alt='Hand' width={130} height={130} />
							<p
								className={classNames('h4', {
									'text-dark': !darkModeStatus,
									'text-light': darkModeStatus,
								})}>
								{t('Everything is ready!') as ReactNode}
							</p>
							<Button
								color='info'
								isLight
								className='w-100'
								onClick={() => {
									localStorage.setItem('facit_asideDocStatus', 'false');
									setDoc(false);
								}}>
								{t('Demo Pages') as ReactNode}
							</Button>
						</CardBody>
					</Card>
				)}
			</AsideBody>
			<AsideFoot>
				<User username={username} />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
