import React, {createContext, useContext, useReducer} from 'react'
import StateReducers, {initialState} from './stateUser'
import PropTypes from 'prop-types';

export const StateContext = createContext();

const StateContextProvider = ({children}) => (
    <StateContext.Provider value={useReducer(StateReducers, initialState)}>
       {children}
    </StateContext.Provider>
)

export const useStateValue = () => useContext(StateContext)

StateContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default StateContextProvider;