import React, {createContext, useContext, useReducer} from 'react'
import stateClinicIdReducer, {initialIDState} from './stateClinicId'
import PropTypes from 'prop-types';

export const StateIdContext = createContext();

const StateIdContextProvider = ({children}) => (
    <StateIdContext.Provider value={useReducer(stateClinicIdReducer, initialIDState)}>
       {children}
    </StateIdContext.Provider>
)

export const useStateIdValue = () => useContext(StateIdContext)

StateIdContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default StateIdContextProvider;