import React, { FC, useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardLabel } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import axios from 'axios';
import AuthContext from '../../../contexts/authContext';
import API_URL from '../../../environment/api';
import Modal, {
	ModalBody,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import './clinic-style.css';
import Spinner from '../../../components/bootstrap/Spinner';

const LoginHeader = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-3 mb-5'>Welcome</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
	setClinicId?:any;
}

const Login: FC<ILoginProps> = ({setClinicId}:any) => {
	useEffect(() => {
		console.clear();

		if(!localStorage.getItem('role') && localStorage.getItem('access_token')){
			axios.get(`https://ebaa.digithup.net/api/auth/logout`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('access_token')}`,
				},
			}).then(() => {
					localStorage.removeItem('access_token');
			})
		}	
		
	}, []);
	const { setUser } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();
	const [clinics, setClinics] = useState([]);
	const [LoginError, setErrorLogin] = useState('');
	const [networkError, setNetworkError] = useState('');
	const [selectModelStatus, setSelectModelStatus] = useState(false);
	const [loginLoad, setLoginLoad] = useState(false);

	const getPath = (role: string | null) => {
		if (role == 'superAdmin') return '/';
		else if (role == 'Admin') return '/main';
		else if (role == 'Patient') return '/patient-home';
		else if (role == 'Doctor') return '/doctor-home';
		else if (role == 'Receptionist') return '/reception-home';
		else if (role == 'Storekeeper') return '/store-home';
		else return 'not-found';
	};

	const authSuperAdminLogin = () => {
		axios
			.post(`${API_URL}/super-admin/login`, {
				email: loginFormik.values.loginUsername,
				password: loginFormik.values.loginPassword,
			})
			.then((res) => {
				if (res.data.data) {
					localStorage.setItem('access_token', res.data.data.access_token);
					setNetworkError('');
					setErrorLogin('');
					localStorage.setItem('role', 'superAdmin');
					if (setUser) {
						setUser(loginFormik.values.loginUsername);
					}
					setTimeout(() => {
						window.location.replace(getPath(localStorage.getItem('role')));
					}, 500);
				} else {
					setLoginLoad(false);
					setErrorLogin(res.data.message);
				}
			})
			.catch((e) => {
				setLoginLoad(false);
				e.response ? setErrorLogin(e.response.data.message) : setNetworkError(e.message);
			});
	};
	const authClinicLogin = () => {
		axios
			.post(`${API_URL}/clinic/login`, {
				email: loginFormik.values.loginUsername,
				password: loginFormik.values.loginPassword,
			})
			.then((res) => {
				if (res.data.data) {
					localStorage.setItem('access_token', res.data.data.access_token);
					axios
						.get(`${API_URL}/clinic/admin/clinics`, {
							headers: {
								Authorization: `Bearer ${localStorage.getItem('access_token')}`,
							},
						})
						.then((res3) => {
							setErrorLogin('');
							if (res3.data.data.length > 1) {
								setClinics(res3.data.data);
								setLoginLoad(false);
								setSelectModelStatus(true);
							} else {
								localStorage.setItem('identity', res3.data.data[0].id);
								setClinicId(res3.data.data[0].id)
								axios
									.get(`${API_URL}/clinic/auth`, {
										headers: {
											Authorization: `Bearer ${localStorage.getItem(
												'access_token',
											)}`,
											'X-Clinic': res3.data.data[0].id,
										},
									})
									.then((res2) => {
										setNetworkError('');
										setErrorLogin('');
										localStorage.setItem('role', res2.data.data.roles[0].name);
										if (setUser) {
											setUser(loginFormik.values.loginUsername);
										}
										setTimeout(() => {
											window.location.replace(
												getPath(localStorage.getItem('role')),
											);
										}, 500);
									});
							}
						})
						.catch((e) => {
							if (e.response.data.message == 'User does not have the right roles.') {
								axios
									.get(`${API_URL}/clinic/auth`, {
										headers: {
											Authorization: `Bearer ${localStorage.getItem(
												'access_token',
											)}`,
										},
									})
									.then((res2) => {
										setNetworkError('');
										setErrorLogin('');
										localStorage.setItem('role', res2.data.data.roles[0].name);
										if (setUser) {
											setUser(loginFormik.values.loginUsername);
										}
										setTimeout(() => {
											window.location.replace(
												getPath(localStorage.getItem('role')),
											);
										}, 500);
									});
							} else {
								setErrorLogin(e.response.data.message);
								console.clear();
								axios
									.get(`https://ebaa.digithup.net/api/auth/logout`, {
										headers: {
											Authorization: `Bearer ${localStorage.getItem(
												'access_token',
											)}`,
										},
									})
									.then(() => {
										localStorage.clear();
										setTimeout(() => {
											window.location.reload();
										}, 500);
									});
							}
						});
				} else {
					setLoginLoad(false);
					setErrorLogin(res.data.message);
				}
			})
			.catch((e) => {
				setLoginLoad(false);
				console.clear();
				e.response ? setErrorLogin(e.response.data.message) : setNetworkError(e.message);
			});
	};
	const authPatiantLogin = () => {
		axios
			.post(`${API_URL}/patient/login`, {
				phone: loginFormik.values.loginUsername,
				password: loginFormik.values.loginPassword,
			})
			.then((res) => {
				localStorage.setItem('access_token', res.data.data.access_token);
				localStorage.setItem('role', 'Patient');
				if (setUser) {
					setUser(loginFormik.values.loginUsername);
				}
				setTimeout(() => {
					window.location.replace(getPath(localStorage.getItem('role')));
				}, 500);
			})
			.catch((e) => {
				setLoginLoad(false);
				e.response ? setErrorLogin(e.response.data.message) : setNetworkError(e.message);
			});
	};
	const handleLogin = () => {

		if(localStorage.getItem('access_token')){
			axios.get(`https://ebaa.digithup.net/api/auth/logout`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		}).then(() => {
		})
		}
		if (loginFormik.values.loginUsername == '' || loginFormik.values.loginPassword == '') {
			setErrorLogin('invalid email or password');
		} else {
			setLoginLoad(true);
			const regEx = /[a-zA-Z]+/;
			if (loginFormik.values.loginUsername == 'superAdmin@ebaa.com') {
				authSuperAdminLogin();
			} else if (regEx.test(loginFormik.values.loginUsername)) {
				authClinicLogin();
			} else {
				authPatiantLogin();
			}
			// dispatch({
			// 	type: actionTypes.SET_USER,
			// 	user: localStorage.getItem('facit_authUsername'),
			// });
		}
	};

	const getClinic = (id: string) => {
		axios
			.get(`${API_URL}/clinic/auth`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('access_token')}`,
					'X-Clinic': id,
				},
			})
			.then((res2) => {
				setNetworkError('');
				setErrorLogin('');
				localStorage.setItem('identity', id);
				setClinicId(id)
				localStorage.setItem('role', res2.data.data.roles[0].name);
				if (setUser) {
					setUser(loginFormik.values.loginUsername);
				}
				setTimeout(() => {
					window.location.replace(getPath(localStorage.getItem('role')));
				}, 500);
			});
	};

	const signFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			signupUsername: '',
			signupnational_id: '',
			signupPhone: '',
			signupPassword: '',
			signupRePassword: '',
		},
		validate: (values) => {
			const errors: {
				signupnational_id?: string;
				signupUsername?: string;
				signupPhone?: string;
				signupPassword?: string;
				signupRePassword?: string;
			} = {};

			if (!values.signupnational_id) {
				errors.signupnational_id = 'Required';
			}
			if (!values.signupUsername) {
				errors.signupUsername = 'Required';
			}
			if (!values.signupPhone) {
				errors.signupPhone = 'Required';
			}

			if (!values.signupPassword) {
				errors.signupPassword = 'Required';
			}
			if (!values.signupRePassword) {
				errors.signupRePassword = 'Required';
			}
			if (values.signupPassword != values.signupRePassword) {
				errors.signupRePassword = 'not identical';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: () => {},
	});

	const loginFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: () => {},
	});

	return (
		<PageWrapper isProtected={false} className='bg-primary vh-100'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-4'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={280} />
									</Link>
								</div>

								<LoginHeader />

								<form className='row g-4'>
									<>
										<div className='col-12'>
											<FormGroup
												className='mb-4'
												id='loginUsername'
												isFloating
												label='Your email or phone'>
												<Input
													autoComplete='username'
													value={loginFormik.values.loginUsername}
													isTouched={loginFormik.touched.loginUsername}
													invalidFeedback={
														loginFormik.errors.loginUsername
													}
													isValid={loginFormik.isValid}
													onChange={loginFormik.handleChange}
													onBlur={loginFormik.handleBlur}
													onFocus={() => {
														loginFormik.setErrors({});
													}}
												/>
											</FormGroup>
											<FormGroup
												id='loginPassword'
												isFloating
												label='Password'>
												<Input
													type='password'
													autoComplete='current-password'
													value={loginFormik.values.loginPassword}
													isTouched={loginFormik.touched.loginPassword}
													invalidFeedback={
														loginFormik.errors.loginPassword
													}
													validFeedback='Looks good!'
													isValid={loginFormik.isValid}
													onChange={loginFormik.handleChange}
													onBlur={loginFormik.handleBlur}
												/>
											</FormGroup>
										</div>
										{LoginError ? (
											<span className='text-danger text-center'>
												{LoginError}
											</span>
										) : (
											''
										)}
										{networkError ? (
											<span className='text-danger text-center'>
												{networkError}
											</span>
										) : (
											''
										)}
										<div className='col-12'>
											<Button
												isDisable = {loginLoad}
												color='info'
												className='w-100 py-3'
												onClick={handleLogin}>
												{loginLoad ? (
													<>
														{/* <Spinner
															size={9}
															isSmall
															isGrow
															className='ms-2 me-2'
														/>
														<Spinner
															size={9}
															isSmall
															isGrow
															className='ms-2 me-2'
														/>
														<Spinner
															size={9}
															isSmall
															isGrow
															className='ms-2 me-2'
														/> */}
															<Spinner
															size={16}
															isSmall
															className='ms-2 me-2'
														/>
													</>
												) : (
													'Login'
												)}
											</Button>
										</div>
									</>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
			<Modal
				fullScreen
				isOpen={selectModelStatus}
				setIsOpen={setSelectModelStatus}
				size='lg'
				titleId={'0'.toString()}>
				<ModalHeader setIsOpen={setSelectModelStatus} hideCloseIcon = {true} className='p-4'>
					<ModalTitle id={'0'}>{'select clinic'}</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-4'>
						<div className='col-md-12'>
							<div className='row'>
								{clinics?.map((c: any) => {
									return (
										<div
											key={c.id}
											className={`${
												darkModeStatus ? 'text-light' : 'text-dark'
											} text-decoration-none col-md-6`}>
											<Card
												onClick={() => getClinic(c.id)}
												style={{ cursor: 'pointer' }}
												className='clinicCard'>
												<CardBody>
													<CardLabel icon='Medication' iconColor='dark'>
														<h4>{c.title}</h4>
														<h5 className='text-light'>
															{c.city}
															{' / '}
															{c.address}
														</h5>
													</CardLabel>
												</CardBody>
											</Card>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<p className='text-danger m-2'>{}</p>
				</ModalBody>
			</Modal>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
