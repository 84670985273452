import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import {
	dashboardPagesMenu,
	AdminPagesMenu,
	PatientPagesMenu,
	DoctorPagesMenu,
	ReceptionPagesMenu,
	storeKeeperPagesMenu
} from '../menu';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	EMPLOYESS:lazy(() => import('../pages/presentation/dashboard/common/SuperAdminEmployees')),
	CLINICS: lazy(() => import('../pages/presentation/dashboard/common/SuperAdminClinics')),
	EXPIRES: lazy(() => import('../pages/presentation/dashboard/common/SuperAdminExpiredClinics')),
	SPECIALIZATIONS: lazy(() => import('../pages/presentation/dashboard/common/SuperAdminClinicsSpecial')),
	SUBSCRIPTIONS: lazy(() => import('../pages/presentation/dashboard/common/SuperAdminClinicsSubscriptions')),
	BILLINGS: lazy(() => import('../pages/presentation/dashboard/common/SuperAdminBillings')),
	EMPLOYEESPECIAL: lazy(() => import('../pages/presentation/dashboard/common/SuperAdminEmpSpecial'))
};


const ADMIN = {
	MAIN: lazy(() => import('../pages/presentation/admin/AdminPage')),
	INSURANCE: lazy(() => import('../pages/presentation/admin/ClinicInsuranceCompany')),
	SERVICES: lazy(() => import('../pages/presentation/admin/ClinicServices')),
	APPOINTMENT: lazy(() => import('../pages/presentation/admin/ClinicAppointment')),
	INVOICES: lazy(() => import('../pages/presentation/admin/ClinicInvoices')),
	DOCTOR_INVOICES: lazy(() => import('../pages/presentation/admin/ClinicDoctordInvoices')),
	STORE: lazy(() => import('../pages/presentation/admin/ClinicStore')),
	EMPLOYEES: lazy(() => import('../pages/presentation/admin/ClinicEmployees')),
	SETTING: lazy(() => import('../pages/presentation/admin/ClinicSettings')),
}

const PATIENT = {
	MAIN: lazy(() => import('../pages/presentation/patient-profile/main')),
	SETTING: lazy(() => import('../pages/presentation/patient-profile/patientSetting'))
}

const STOREKEEPER = {
	MAIN: lazy(() => import('../pages/presentation/store-keeper/StoreKeeper')),
}

const DOCTOR = {
	MAIN: lazy(() => import('../pages/presentation/doctor-profile/DoctorProfile')),
	APPOINTMENT: lazy(() => import('../pages/presentation/doctor-profile/DoctorAppointments')),
	EXAMINATIONS: lazy(() => import('../pages/presentation/doctor-profile/DoctorExaminations')),
	CALENDAR: lazy(() => import('../pages/presentation/doctor-profile/DoctorCalendar')),
	STORE: lazy(() => import('../pages/presentation/doctor-profile/DoctorStore'))
}

const RECEPTION = {
	MAIN: lazy(() => import('../pages/presentation/reception/ReceptionPage')),
	APPOINTMENTS: lazy(() => import('../pages/presentation/reception/ReceptionAppointment')),
	CALENDER: lazy(() => import('../pages/presentation/reception/ReceptionCalender')),
	PATIENTS: lazy(() => import('../pages/presentation/reception/ReceptionPatients')),
	SERVICES: lazy(() => import('../pages/presentation/admin/ClinicServices'))
}

const documentation: RouteProps[] = [

	// {
	// 	path: AdminPagesMenu.stores.path,
	// 	element: <ADMIN.STORE />,
	// },
	// {	
	// 	path: AdminPagesMenu.employees.path,
	// 	element: <ADMIN.EMPLOYEE />,
	// },
	{
		path: AdminPagesMenu.appointment.path,
		element: <ADMIN.APPOINTMENT/>,
	},
	{
		path: AdminPagesMenu.invoices.path,
		element: <ADMIN.INVOICES/>,
	},
	{
		path: AdminPagesMenu.doctorInvoices.path,
		element: <ADMIN.DOCTOR_INVOICES/>,
	},
	{
		path:AdminPagesMenu.main.path,
		element:<ADMIN.MAIN/>
	},
	{
		path:AdminPagesMenu.store.path,
		element:<ADMIN.STORE/>
	},
	{
		path:AdminPagesMenu.insurance_company.path,
		element:<ADMIN.INSURANCE/>
	},
	{
		path:AdminPagesMenu.setting.path,
		element:<ADMIN.SETTING/>
	},
	
	{
		path:AdminPagesMenu.services.path,
		element:<ADMIN.SERVICES/>
	},
	{
		path:AdminPagesMenu.employees.path,
		element:<ADMIN.EMPLOYEES/>
	},
	{
		path:dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path:dashboardPagesMenu.expires.path,
		element: <LANDING.EXPIRES/>,
	},
	{
		path:dashboardPagesMenu.employees.path,
		element:<LANDING.EMPLOYESS/>
	},	
	{
		path:dashboardPagesMenu.clinics.path,
		element:<LANDING.CLINICS/>
	},
	{
		path:dashboardPagesMenu.specializations.path,
		element:<LANDING.SPECIALIZATIONS/>
	},
	{
		path:dashboardPagesMenu.billings.path,
		element:<LANDING.BILLINGS/>
	},
	{
		path:dashboardPagesMenu.employeesSpecializations.path,
		element:<LANDING.EMPLOYEESPECIAL/>
	},
	{
		path:dashboardPagesMenu.subscriptions.path,
		element:<LANDING.SUBSCRIPTIONS/>
	},
	{
		path:PatientPagesMenu.main.path,
		element:<PATIENT.MAIN/>
	},
	{
		path:PatientPagesMenu.setting.path,
		element:<PATIENT.SETTING/>
	},
	{
		path:storeKeeperPagesMenu.main.path,
		element:<STOREKEEPER.MAIN/>
	},
	{
		path:DoctorPagesMenu.main.path,
		element:<DOCTOR.MAIN/>
	},
	{
		path:DoctorPagesMenu.appointments.path,
		element:<DOCTOR.APPOINTMENT/>
	},
	{
		path:DoctorPagesMenu.examinations.path,
		element:<DOCTOR.EXAMINATIONS/>
	},
	{
		path:DoctorPagesMenu.calendar.path,
		element:<DOCTOR.CALENDAR/>
	},
	{
		path:DoctorPagesMenu.store.path,
		element:<DOCTOR.STORE/>
	},
	{
		path:ReceptionPagesMenu.main.path,
		element:<RECEPTION.MAIN/>
	}
	,
	{
		path:ReceptionPagesMenu.appointment.path,
		element:<RECEPTION.APPOINTMENTS/>
	}
	,
	{
		path:ReceptionPagesMenu.calender.path,
		element:<RECEPTION.CALENDER/>
	}
	,
	{
		path:ReceptionPagesMenu.patients.path,
		element:<RECEPTION.PATIENTS/>
	}
];
const contents = [...documentation];

export default contents;
