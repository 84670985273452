import React from 'react';
import { createRoot } from 'react-dom/client'; // For React 18
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import StateContextProvider from './contexts/userContext';
import StateIdContextProvider from './contexts/ClinicIDContext';
import './index.css';
import './i18n';


const children = (
	<div className='mmm'>
		<AuthContextProvider>
			<ThemeContextProvider>
				<StateContextProvider>
					<StateIdContextProvider>
						{/* <React.StrictMode> */}
						<App />
						{/* </React.StrictMode> */}
					</StateIdContextProvider>
				</StateContextProvider>
			</ThemeContextProvider>
		</AuthContextProvider>
	</div>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
