import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import { useTranslation } from 'react-i18next';

const DashboardHeader = () => {

	const {t} = useTranslation();
	const superAdmin = localStorage.getItem('facit_authUsername')

	return (
		<>
		{localStorage.getItem('role') == 'superAdmin' && 	
		<Header>
			<HeaderLeft>
				<h3>{t('dashboard.dashboard')}</h3>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>}
		
		</>
	
	);
};

export default DashboardHeader;
