export const initialIDState = {
    id: null
}

export const actionIDTypes = {
    SET_ID: 'SET_ID'
}

const stateClinicIdReducer = (state, action) => {
    switch (action.type) {
        case actionIDTypes.SET_ID:
            return {
                id: action.id
            }
        default:
            return state
    }
}

export default stateClinicIdReducer