

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'dashboard.dashboard',
		path: '/',
		icon: 'Dashboard',
	},
	clinics: {
		id: 'admin-clinics',
		text: 'dashboard.clinics',
		path: 'admin-clinics',
		icon: 'Apartment',
	},
	specializations: {
		id: 'specializations',
		text: 'dashboard.specializations',
		path: 'clinic-specializations',
		icon: 'ViewStream',
	},
	subscriptions: {
		id: 'subscriptions',
		text: 'dashboard.subscriptions',
		path: 'subscriptions',	
		icon: 'ViewCarousel',
	},
	billings: {
		id: 'billings',
		text: 'dashboard.billings',
		path: 'billings',
		icon: 'RequestQuote',
	},
	expires: {
		id: 'expired',
		text: 'dashboard.expires',
		path: 'expired-clinics',
		icon: 'Block',
	},
	employees: {
		id: 'users',
		text: 'dashboard.employees',
		path: 'admin-users',
		icon: 'groups',
	},
	employeesSpecializations: {
		id: 'usersSpecialization',
		text: 'dashboard.employeesSpecializations',
		path: 'users-specializations',	
		icon: 'AssignmentInd',
	}
};

export const PatientPagesMenu = {
	main: {
		id: 'home',
		text: 'patient.main',
		path: 'patient-home',
		icon: 'Dashboard',
	},
	setting: {
		id: 'patientSetting',
		text: 'patient.settings',
		path: 'patient-setting',
		icon: 'settings',
	}
}

export const storeKeeperPagesMenu = {
	main: {
		id: 'storeHome',
		text: 'storeKeeper.main',
		path: 'store-home',
		icon: 'Archive',
	},
}
export const ReceptionPagesMenu = {
	main: {
		id: 'home',
		text: 'reception.main',
		path: 'reception-home',
		icon: 'Dashboard',
	},
	patients: {
		id: 'patients',
		text: 'reception.patients',
		path: 'reception-patients',
		icon: 'AirlineSeatFlat',
	},
	appointment: {
		id: 'appointments-Reception',
		text: 'reception.appointments',
		path: 'reception-appointment',
		icon: 'AccessTimeFilled',
	},
	calender: {
		id: 'calender',
		text: 'reception.calender',
		path: 'calender-appointment',
		icon: 'EventNote',
	}

}

export const DoctorPagesMenu = {
	main: {
		id: 'home',
		text: 'doctor.main',
		path: 'doctor-home',
		icon: 'Dashboard',
	},
	appointments: {
		id: 'doctorAppointments',
		text: 'doctor.appointments',
		path: 'doctor-appointments',
		icon: 'AccessTimeFilled',
	},
	examinations: {
		id: 'doctorExaminations',
		text: 'doctor.examinations',
		path: 'doctor-examinations',
		icon: 'LocalHospital',
	},
	calendar: {
		id: 'doctorCalendar',
		text: 'doctor.calendar',
		path: 'doctor-calendar',
		icon: 'EventNote',
	},
	store: {
		id: 'doctorStore',
		text: 'doctor.store',
		path: 'doctor-store',
		icon: 'Archive',
	}
}

export const AdminPagesMenu = {
	main: {
		id: 'main',
		text: 'admin.main',
		path: 'main',
		icon: 'Dashboard',
	},
	insurance_company: {
		id: 'insurance_company',
		text: 'admin.insurance_company',
		path: 'add-insurance-company',
		icon: 'Apartment',
	},
	appointment: {
		id: 'appointments_admin',
		text: 'admin.appointment',
		path: 'appointment',
		icon: 'AccessTimeFilled',
	},
	invoices: {
		id: 'invoices-admin',
		text: 'admin.invoices',
		path: 'invoices',
		icon: 'Description',
	},
	doctorInvoices: {
		id: 'doctorInvoices',
		text: 'admin.doctorInvoices',
		path: 'doctors-invoice',
		icon: 'ContactPage',
	},	
	store: {
		id: 'AdminStore',
		text: 'admin.store',
		path: 'store',
		icon: 'Archive',
	},
	services: {
		id: 'clinic-services',
		text: 'admin.services',
		path: 'clinic-services',
		icon: 'MedicalServices',
	},
	employees: {
		id: 'clinicEmployees',
		text: 'admin.employees',
		path: 'clinic-employees',
		icon: 'groups',
	},
	setting: {
		id: 'setting',
		text: 'admin.settings',
		path: 'setting',
		icon: 'settings',
	}
	
};

// export const pageLayoutTypesPagesMenu = {
// 	layoutTypes: {
// 		id: 'layoutTypes',
// 		text: 'Page Layout Types',
// 	},
// 	blank: {
// 		id: 'blank',
// 		text: 'Blank',
// 		path: 'page-layouts/blank',
// 		icon: 'check_box_outline_blank ',
// 	},
// 	pageLayout: {
// 		id: 'pageLayout',
// 		text: 'Page Layout',
// 		path: 'page-layouts',
// 		icon: 'BackupTable',
// 		subMenu: {
// 			headerAndSubheader: {
// 				id: 'headerAndSubheader',
// 				text: 'Header & Subheader',
// 				path: 'page-layouts/header-and-subheader',
// 				icon: 'ViewAgenda',
// 			},
// 			onlyHeader: {
// 				id: 'onlyHeader',
// 				text: 'Only Header',
// 				path: 'page-layouts/only-header',
// 				icon: 'ViewStream',
// 			},
// 			onlySubheader: {
// 				id: 'onlySubheader',
// 				text: 'Only Subheader',
// 				path: 'page-layouts/only-subheader',
// 				icon: 'ViewStream',
// 			},
// 			onlyContent: {
// 				id: 'onlyContent',
// 				text: 'Only Content',
// 				path: 'page-layouts/only-content',
// 				icon: 'WebAsset',
// 			},
// 		},
// 	},
// 	asideTypes: {
// 		id: 'asideTypes',
// 		text: 'Aside Types',
// 		path: 'aside-types',
// 		icon: 'Vertical Split',
// 		subMenu: {
// 			defaultAside: {
// 				id: 'defaultAside',
// 				text: 'Default Aside',
// 				path: 'aside-types/default-aside',
// 				icon: 'ViewQuilt',
// 			},
// 			minimizeAside: {
// 				id: 'minimizeAside',
// 				text: 'Minimize Aside',
// 				path: 'aside-types/minimize-aside',
// 				icon: 'View Compact',
// 			},
// 		},
// 	},
// };

// export const gettingStartedPagesMenu = {
// 	gettingStarted: {
// 		id: 'gettingStarted',
// 		text: 'Getting Started',
// 		path: 'getting-started',
// 		icon: 'Book',
// 		subMenu: {
// 			installation: {
// 				id: 'installation',
// 				text: 'Installation',
// 				path: 'getting-started/installation',
// 				icon: 'BuildCircle',
// 			},
// 			dev: {
// 				id: 'dev',
// 				text: 'Development',
// 				path: 'getting-started/development',
// 				icon: 'DirectionsRun',
// 			},
// 			folderStructure: {
// 				id: 'folderStructure',
// 				text: 'Folder Structure',
// 				path: 'getting-started/folder-structure',
// 				icon: 'SnippetFolder',
// 			},
// 			bootstrapVariables: {
// 				id: 'bootstrapVariables',
// 				text: 'Bootstrap Variables',
// 				path: 'getting-started/bootstrap-variables',
// 				icon: 'SnippetFolder',
// 			},
// 			projectStructure: {
// 				id: 'projectStructure',
// 				text: 'Project Structure',
// 				path: 'getting-started/project-structure',
// 				icon: 'SnippetFolder',
// 			},
// 		},
// 	},
// 	routes: {
// 		id: 'routes',
// 		text: 'Routes & Pages',
// 		path: 'routes',
// 		icon: 'AltRoute',
// 		subMenu: {
// 			router: {
// 				id: 'router',
// 				text: 'Router',
// 				path: 'routes/router',
// 				icon: 'Router',
// 			},
// 		},
// 	},
// };

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
