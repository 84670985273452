import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import AsideRoutes from '../layout/Aside/AsideRoutes';
import { useStateValue } from '../contexts/userContext';
import { actionTypes } from '../contexts/stateUser';
import Login from './../pages/presentation/auth/Login';
import { Height } from '../components/icon/material-icons';
// import { ClinicIdProvider } from '../contexts/ClinicIDContext';
import { useTranslation } from 'react-i18next';

const App = () => {
	getOS();

	const { i18n } = useTranslation();

	const [{ user }, dispatch] = useStateValue();

	useEffect(() => {
		dispatch({
			type: actionTypes.SET_USER,
			user: localStorage.getItem('facit_authUsername'),
		});
	}, [dispatch]);
	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	// @ts-ignore
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	// let lang = localStorage.getItem('i18nextLng') || 'en';
	const [clinicId, setClinicId] = useState('');
	// document.documentElement.clinicId = clinicId;

	return (
		<div  dir={i18n.language=="ar"?"rtl":"ltr"}>

	
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<TourProvider
					steps={steps}
					styles={styles}
					showNavigation={false}
					showBadge={false}>
					<div
						ref={ref}
						className='app'
						style={{
							backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
							zIndex: fullScreenStatus ? 1 : undefined,
							overflow: fullScreenStatus ? 'scroll' : undefined,
						}}>
						<Router>
							{/* <ClinicIdProvider value={{ clinicId, setClinicId }}> */}

							{user ? (
								<>
									<div className='row gx-0'>
										<div className={`${i18n.language=="en"?"lll":"rrr"}`}>
											<AsideRoutes />
										</div>
										<div className='col-2'>
											
										</div>

										<div className="col-10">
											<Wrapper />
										</div>
									</div>

									{/* <AsideRoutes />
									<Wrapper /> */}
								</>
							) : (
								<Login setClinicId={setClinicId} />
							)}
							{/* </ClinicIdProvider> */}
						</Router>
					</div>
					<Portal id='portal-notification'>
						<ReactNotifications />
					</Portal>
				</TourProvider>
			</ToastProvider>
		</ThemeProvider>
		</div>
	);
};

export default App;
